import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Location } from '@angular/common'
import { GlobalService } from "../services/Global/global.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { MatDrawerMode, MatSidenav } from "@angular/material/sidenav";
import { UsersService } from '../services/General/users.service';
import { User } from './../models/users';
import { Router, NavigationEnd } from '@angular/router'
import { GeneralService } from '../services/General/general.service';

declare const $: any;

export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    icon?: string;
    type?: string;
}

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard',
        title: 'Home',
        type: 'link',
        icontype: 'home',
        children: [
        ]
    },
    {
        path: '/setups',
        title: 'Setups',
        type: 'sub',
        icontype: 'settings',
        collapse: 'tables',
        children: [
            { path: 'master-setups', title: 'Master Setups' }
            // ,{path: 'pagination', title: 'Pagination'}

        ]
    },
    {
        path: '/employees',
        title: 'Employees',
        type: 'link',
        icontype: 'person'
    },
    {
        path: '/user',
        title: 'Users',
        type: 'link',
        icontype: 'account_box'
    }
];
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnDestroy, OnInit, AfterViewInit {
    pageTitle: string;
    user: User;
    rolesData: any;
    userName: string;
    entryDate: string;
    userRole: any = '';
    overOrSide: boolean;
    mobileQuery: MediaQueryList;
    count: number = 0;
    private history: string[] = [];

    fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

    fillerContent = Array.from({ length: 50 }, () =>
        `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

    private _mobileQueryListener: () => void;

    constructor(private cd: ChangeDetectorRef, private router: Router, private location: Location, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public global: GlobalService, private userService: UsersService, public roleService: GeneralService) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects)
            }
        })
    }

    ngOnInit() {
        if (this.count == 0) {
            this.roleService.getRoles()
            this.count = this.count + 1
        }
        // if(this.pageTitle != this.global.pageTitle){
        setTimeout(() => {
            this.pageTitle = this.global.pageTitle
            this.ngOnInit
        }, 20);
        // }
        // else{
        // this.pageTitle = this.global.pageTitle
        console.log(this.pageTitle)
        // }
        try {
            this.user = JSON.parse(this.userService.readUserLocalData());
            console.log(this.user)
            this.userName = this.user.username
            this.entryDate = this.user.entrydate.slice(0, 4);
            if (this.mobileQuery.matches === false) {
                this.overOrSide = true;
            }
        }
        catch (x) {
            console.log(x)
        }
        // console.log(this.user)
        // console.log("**********************")
        // console.log("From Sidebar Component")
        // console.log(this.global.roleSignedIn)
        // console.log("**********************")
    }

    ngAfterViewInit(): void {
        this.pageTitle = this.global.pageTitle
        this.cd.detectChanges()
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    isMobile(e) {
        this.overOrSide = this.mobileQuery.matches === false;
        this.openSideNav(e)
        this.global.loading = false
    }

    setUserRole() {
        try {
            var sampleData: any = Object.values(this.rolesData)[0]
            this.rolesData = sampleData.filter(i => i.roleid.toString() == this.user.roleid.toString())
            this.userRole = this.rolesData[0].rname
        }
        catch (x) {
            console.log(x)
        }
    }
    openSideNav(e) {
        e.toggle()
        this.ngOnInit()
        if (this.global.pageTitle == "Stock Taking" && this.global.loading == false) {
            // this.global.loading = true
        }
    }
    back() {
        this.history.pop()
        if (this.history.length > 0) {
            this.location.back()
        } else {
            this.router.navigateByUrl('/home')
        }
        this.pageTitle = this.global.pageTitle
        this.ngOnInit()
    }
    Logout() {
        this.global.loading = true
        this.router.navigate(["/logout"])
    }


}
