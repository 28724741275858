import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DateHolder } from "../../models/date-holder";
import { UsersService } from "../General/users.service";

var weekArray = [
  "SUN_DAY",
  "MON_DAY",
  "TUE_DAY",
  "WED_DAY",
  "THU_DAY",
  "FRI_DAY",
  "SAT_DAY",
];
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  // dateholder : DateHolder;
  private _loading: boolean = false;
  private _title: string;
  private _tdate: string;
  private _day: string;
  private _nextDayName: string;
  private _nextDD: string;
  private _monYear;
  val: any;
  private _distributer_id: string;
  private _csf_id: string;
  private _ordNo: string;
  private _ret_id: string;
  dateholder = new DateHolder();
  public pageTitle = "COMS";
  public roleSignedIn: string;
  public retailer_id: string;
  public retailer_name: string;
  public orderNumber: any;
  public multiUserSignInAttempt: boolean = false;
  public totalShops: number = 0;
  public noOrders: number = 0;
  public orderReceived: number = 0;
  public delivered: number = 0;
  public errorTitle: string;
  public errorMessage: string;
  public showError: boolean = false;
  public isDNcreated: boolean = false;
  public currentDate: string = '';
  public isBackDateOrder: boolean = false;
  public DN_Number: string;
  public DN_Detail_NO: string;
  //
  //
  isTracking: boolean;
  csfLatitude = "N/A";
  csfLongitude = "N/A";
  centered = true;
  radius: number = 80;
  //
  //
  NearByShopLoader: boolean = false;
  NearByShop: boolean = false;
  trackLocation: boolean = false;
  trackCSF: boolean = true;

  constructor(public router: Router, public userService: UsersService) {}

  set ret_id(value: string) {
    this._ret_id = value;
  }
  get ret_id(): string {
    return this._ret_id;
  }
  set csf_id(value: string) {
    this._csf_id = value;
  }
  get csf_id(): string {
    return this._csf_id;
  }
  set distributer_id(value: string) {
    this._distributer_id = value;
  }
  get distributer_id(): string {
    return this._distributer_id;
  }

  set ordNo(value: string) {
    this._ordNo = value;
  }
  get ordNo(): string {
    return this._ordNo;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get tdate(): string {
    return this._tdate;
  }
  get day(): string {
    return this._day;
  }

  get nextDayName(): string {
    return this._nextDayName;
  }
  get nextDD(): string {
    return this._nextDD;
  }

  get monYear(): string {
    return this._monYear;
  }

  DateFiltering(todateValue) {
    this.val = todateValue.toDateString();
    //console.log(this.val)
    this.dateholder.curDay = this.val.substr(0, 3);
    //console.log(this.dateholder.curDay)
    this.dateholder.ToDay = this.val.substr(8, 2);
    //console.log(this.ToDay)
    this.dateholder.ToMonth = this.val.substr(4, 3);
    this.dateholder.ToMonth = this.dateholder.ToMonth.toUpperCase();
    //console.log(this.dateholder.ToMonth)
    this.dateholder.ToYear = this.val.substr(11, 4);
    //console.log(this.ToYear)
    this._monYear = this.dateholder.ToMonth + "-" + this.dateholder.ToYear;

    this.dateholder.ToDate =
      this.dateholder.ToDay +
      "-" +
      this.dateholder.ToMonth +
      "-" +
      this.dateholder.ToYear;

    this._tdate = this.dateholder.ToDate;

    this._day = this.dateholder.curDay.toUpperCase() + "_DAY";
    //this.dateholder.curDay = this.dateholder.curDay.toUpperCase()+'_DAY'
    // console.log(this._day)
  }

  logout() {
    try {
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.userService.user = {};
      this.router.navigate(["login"]);
      console.log(this._csf_id);
      console.log(this._distributer_id);
    } catch (e) {}
  }

  filterWeekDate(dd, dayName) {
    let _dd = dd.substr(0, 2);
    let rest_dd = dd.substr(2, dd.lenght);
    let sum = +_dd + +"1";
    this._nextDD = sum.toString();
    this._nextDD = this._nextDD + rest_dd;
    for (var i = 0; i < weekArray.length; i++) {
      if (weekArray[i] == dayName) {
        this._nextDayName = weekArray[i + 1];
      }
    }
  }

  activateLocation(){
    this.accessLocation()
    this.trackMe()
    setTimeout(() => {
      this.NearByShop = true
      this.NearByShopLoader = false
      console.log('after 2 seconds')
    }, 2000);
  }

  accessLocation() {
    if (this.trackLocation == true) {
      this.trackLocation = false
      this.NearByShopLoader = false
      this.NearByShop = false
      console.log(this.trackLocation)
    }
    else {
      this.trackLocation = true
      this.NearByShopLoader = true
      setTimeout(() => {
        this.NearByShop = true
        this.NearByShopLoader = false
        console.log('after 2 seconds')
      }, 2000);
      console.log(this.trackLocation)
    }
  }

  trackMe() {
    if (navigator.geolocation) {
      this.isTracking = true;
      navigator.geolocation.watchPosition((position) => {
        this.csfLatitude = Number(position.coords.latitude).toFixed(2)
        this.csfLongitude = Number(position.coords.longitude).toFixed(2)
        console.log(this.csfLatitude)
        console.log(this.csfLongitude)
      });
    } else {
      this.showError = true;
      this.errorTitle = "Error!";
      this.errorMessage = "Geolocation is not supported by this browser.";
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position:any) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude +
            " Longitude: " + position.coords.longitude);
            this.csfLatitude = position.coords.latitude;
            this.csfLongitude = position.coords.longitude;
        }
      },
        (error: any) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
}
