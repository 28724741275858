import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../../models/users';
import { Roles } from '../../models/roles'
import { GlobalService } from '../Global/global.service';
// import { UsersService } from './../services/users.service';
import { ConfigurationService } from '../Config/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    httpOptions: HttpHeaders
    rolesData: Roles
    roles: any
    user: User
    userRole: string = 'abc'
    isDataReceived: boolean = false

    constructor(private http: HttpClient, public global:GlobalService, public _config:ConfigurationService) {
        this.httpOptions = new HttpHeaders({
            'Authorization': 'Bearer ' + sessionStorage.getItem('oauthToken'),
            'Content-type': 'application/json'
        })
        // this.user = JSON.parse(localStorage.getItem('user'));
        this.getRoles()
        console.log(sessionStorage.getItem('oauthToken'))
    }

    loadRoles(): Observable<Roles> {
        return this.http.get<Roles>(environment.apiUrl + 'roles',
            { headers: this._config.getHeaderInformation() })
            .pipe(catchError(this.errorHandler))
    }

    errorHandler(error: HttpErrorResponse) {
        return throwError(error)
    }

    getRoles() {
        try {
            this.loadRoles().subscribe(
                x => {
                    this.rolesData = x
                    console.log(this.rolesData)
                    this.setRoles()
                }
            )
        }
        catch (x) {
            console.log(x)
        }
    }

    setRoles() {
        try {
            var sampleData: any = Object.values(this.rolesData)[0]
            console.log(sampleData)
            this.user = JSON.parse(localStorage.getItem('user'));
            console.log(this.user.roleid.toString())
            this.roles = sampleData.filter(i => i.roleid.toString() == this.user.roleid.toString())
            console.log("**************")
            console.log(this.roles)
            console.log("**************")
            this.userRole = this.roles[0].rname
            console.log(this.userRole)
            this.isDataReceived = true
            this.global.roleSignedIn = this.userRole
            console.log(this.global.roleSignedIn);

        }
        catch (x) {
            // console.log(x)
        }
    }

}
