import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/Global/global.service';
import { UsersService } from 'src/app/services/General/users.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public userService: UsersService, private spinner: NgxSpinnerService, public router: Router, public global: GlobalService) { }


  ngOnInit(): void {
    setTimeout(() => {
      this.logout()
    }, 1000);
  }
  logout() {
    try {
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.userService.user = {}
      this.router.navigate(['/login']);
      this.global.loading = false;
    } catch (e) {
    }
  }
}
