import { Injectable } from '@angular/core';

@Injectable()
export class OauthToken {
    access_token:string;
    token_type:string;
    refresh_token:string;
    expires_in:string;
    scope:string;
}
