import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appRoleBasedAuthorization]'
})
export class RoleBasedAuthorizationDirective {

    /*allowedRoles: string[];
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private _loginService: LoginService) {
    }

    @Input()
    set appRoleBasedAuthorization(allowedRoles: string[]) {
        this.allowedRoles = allowedRoles;
        if (!this.allowedRoles || this.allowedRoles.length === 0 || sessionStorage.getItem('rights') === undefined) {
            this.viewContainer.clear();
            return;
        }

        // const authorities: Role = this._loginService.currentUser.role;
        const rights: SecurityRights[] = JSON.parse(sessionStorage.getItem('rights'));
        /!*for (let i = 0; i < authorities.length; i++) {
            rights = Array.from(authorities[i].securityRights);
        }*!/
        let roles: string[] = [];
        /!*for (const right of rights) {
            if (right.flag === 'F') {
                roles.push(right.links);
            }
        }*!/
        for (let i = 0; i < rights.length; i++) {
            if (rights[i].flag === 'F') {
                roles.push(rights[i].links);
            }
        }
        // authorities.push(this._loginService.currentUser.roles.forEach(role => role.securityRights));

        /!*for (let i = 0; i < rights.length; i++) {
            if (rights[i].flag === 'F') {
                roles.push(rights[i].links);
            }
        }*!/

        const allowed: boolean = roles.filter(
            role => this.allowedRoles.includes(role)).length > 0;

        if (allowed) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }

    }*/

}
