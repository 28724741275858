export class DateHolder {
  curDay: string;
  ToDay: string;
  ToMonth: string;
  ToYear: string;
  ToDate: string;
  frmDay: string;
  frmDate: string;
  frmMonth: string;
  frmYear: string;
  csfid?: string;
  shopid?: string;
  disid?: any;
  monYear?: string;
  ord?: string;
  showme?: boolean;
  ret_id?: number;
  uid?: string;
}
