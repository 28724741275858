import { Injectable } from '@angular/core';
import {OauthToken} from '../../models/oauthToken';


@Injectable({
    providedIn: 'root'
})
export class OauthTokenService {

    public _oauthToken: OauthToken = new OauthToken();
    private key = 'oauthToken';
    private refresh = 'refresh';
    private expires = 'expires';
    public value = this._oauthToken.access_token;
    private refresh_token = this._oauthToken.refresh_token;
    private expires_in = this.oauthToken.expires_in;
    public logout: boolean;


    constructor() { }


    get oauthToken() {
        if (this._oauthToken.access_token !== undefined) {
            return this._oauthToken;
        } else if (!this.logout) {
            this._oauthToken = new OauthToken();
            this._oauthToken.access_token = sessionStorage.getItem(this.key);
            return this._oauthToken;
        }
    }


    set oauthToken(token) {
        this._oauthToken = token;
        this.value = this._oauthToken.access_token;
        this.refresh_token = this.oauthToken.refresh_token;
        this.expires_in = this.oauthToken.expires_in;
        sessionStorage.setItem(this.key, this.value);
        sessionStorage.setItem(this.refresh, this.refresh_token);
        sessionStorage.setItem(this.expires, this.expires_in);
        console.log("******************")
        console.log("Token Setted Succesfully")
        console.log("******************")
    }


}
