import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import * as global from "../../../environments/global";
import { OauthTokenService } from "../Authentication/oauth-token.service";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Csf } from "../../models/csf";
import { GlobalService } from "../Global/global.service";
import { catchError } from "rxjs/internal/operators/catchError";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  constructor(
    public http: HttpClient,
    public tokenService: OauthTokenService
  ) {}

  getHeaderInformation() {
    var token = sessionStorage.getItem("oauthToken");
    var httpOptions;

    if (token != null && token != undefined) {
      httpOptions = new HttpHeaders({
        Authorization: "Bearer " + sessionStorage.getItem("oauthToken"),
        "Content-type": "application/json",
      });
    } else if (
      this.tokenService.value != null &&
      this.tokenService != undefined
    ) {
      httpOptions = new HttpHeaders({
        Authorization: "Bearer " + this.tokenService.value,
        "Content-type": "application/json",
      });
    }
    else{
      httpOptions = null ;
    }

    return httpOptions ;
  }
}
