import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../../models/users';
import { ConfigurationService } from '../Config/configuration.service';

@Injectable({
  providedIn: 'root'
})


export class UsersService {
  httpOptions: HttpHeaders
  user: User

  constructor(private _http: HttpClient, public _config:ConfigurationService) {

    this.httpOptions = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('oauthToken'),
      'Content-type': 'application/json'
    })
    console.log(sessionStorage.getItem('oauthToken'))

  }

  getUser(uid): Observable<any> {
    this.httpOptions = new HttpHeaders({
      'Authorization': 'Bearer ' + sessionStorage.getItem('oauthToken'),
      'Content-type': 'application/json'
    })
    return this._http.get<any>(environment.apiUrl + 'auth/user/' + uid,
      { headers: this._config.getHeaderInformation() })
      .pipe(catchError(this.errorHandler))
  }



  setUserInLocal(uid) {
    if (sessionStorage.getItem('oauthToken')) {

      this.getUser(uid).subscribe(x => {
        try {
          console.log(x);

          let user: User = {}
          if (x && x.items && x.items.length) {
            if (x.items[0].userid.substr(0, 1) == 'C') {
              user.userid = x.items[0].userid.substr(1, 8)
            }
            else {
              user.userid = x.items[0].userid
            }
            user.entrydate = x.items[0].entrydate
            user.isactive = x.items[0].isactive
            user.roleid = x.items[0].roleid
            user.distributer_id = x.items[0].distributer_id
            user.username = x.items[0].username
            user.userpwd = x.items[0].userpwd
            user.valid = x.items[0].valid

            console.log(JSON.stringify(user))
            console.log()
            this.user = user
            localStorage.setItem('user', JSON.stringify(user))
          }
        }
        catch (x) {
          console.log(x)
        }


      })



    }
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }

  readUserLocalData() {
    try {
      if (localStorage.getItem('user')) {
        return localStorage.getItem('user');
      }
      else {
        console.log("Error Found")
        console.log("Reading Old Data")
        console.log(this.user)
      }
    }
    catch (x) {

    }
  }
  getUserFullName() {
    try {

    }
    catch (x) {

    }
  }

}
