import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthGuardService } from "./services/Authentication/auth-guard.service";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { LogoutComponent } from './pages/logout/logout/logout.component';

const rootRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: '',
        component: SidebarComponent,
        children: [
            {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                // canActivate: [AuthGuardService]

            },
            {
                path: '',
                loadChildren: './home/home.module#HomeModule',
            },
            {
                path: '',
                loadChildren: './notifications/notifications.module#NotificationsModule',
            },
            {
                path: '',
                loadChildren: './stock-taking/stock-taking.module#StockTakingModule'
            },
            {
                path: '',
                loadChildren: './route-plan/route-plan.module#RoutePlanModule'
            },
            {
                path: '',
                loadChildren: './report/report.module#ReportModule'
            },
            {
                path: '',
                loadChildren: './order/order.module#OrderModule'
            },
            {
                path: '',
                loadChildren: './invoice/invoice.module#InvoiceModule'
            },
            {
                path: '',
                loadChildren: './qr-scan/qr-scan.module#QrScanModule'
            },
            {
                path: '',
                loadChildren: './non-visit-reason/non-visit-reason.module#NonVisitReasonModule'
            },
            {
                path: '',
                loadChildren: './return/return.module#ReturnModule',
            },
            {
                path: '',
                loadChildren: './new-order/new-order.module#NewOrderModule',
            },
            {
                path: '',
                loadChildren: './incentive/incentive.module#IncentiveModule',
            },
            {
                path: '',
                loadChildren: './csfsetting/csfsetting.module#CsfsettingModule',
            },
            {
                path: '',
                loadChildren: './addshops/addshops.module#AddshopsModule',
            },
            {
                path: '',
                loadChildren: './existing-product/existing-product.module#ExistingProductModule'
            },
            {
                path: '',
                loadChildren: './reschedule/reschedule.module#RescheduleModule'
            },
            {
                path: '',
                loadChildren: './delivery-note/delivery-note.module#DeliveryNoteModule'
            },
            {
                path: '',
                loadChildren: './undelivered-order/undelivered-order.module#UndeliveredOrderModule'
            },
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './pages/pages.module#PagesModule'
            }
        ]
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' }
];

@NgModule({
    imports: [RouterModule.forRoot(rootRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
