import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('in header interceptor');
        if (req.headers.has('Content-type')) {
            console.log('handling login');
            return next.handle(req);
        } else {
            req = req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('oauthToken')
                }
            });
            return next.handle(req);
        }
    }

}
