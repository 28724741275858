import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import {RouterModule} from '@angular/router';
import {RoleBasedAuthorizationDirective} from './role-based-authorization.directive';
import {MaterialModule} from "../material.module";

@NgModule({
    imports: [ RouterModule, CommonModule, MaterialModule ],
    declarations: [ SidebarComponent, RoleBasedAuthorizationDirective ],
    exports: [ SidebarComponent ]
})
export class SidebarModule { }
