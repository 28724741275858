import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "./services/Global/global.service";
/*import { SwUpdate } from '@angular/service-worker';*/

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    showPopup:boolean=true


    constructor( public router: Router, private spinner: NgxSpinnerService, public globalService: GlobalService) {
        /*updates.available.subscribe(event => {
            updates.activateUpdate().then(() => document.location.reload());
        });*/
    }
    ngOnInit() {
        this.spinner.show();

        /*setTimeout(() => {
            /!** spinner ends after 5 seconds *!/
            this.spinner.hide();
        }, 5000000);*/
    }

    closeMultiUserErrorPopup(){
        this.globalService.multiUserSignInAttempt = false ;
    }

 
}
